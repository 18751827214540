// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBrBqn9t3LlGl615g9RBg1kU8oTMvJe6EE",
  authDomain: "eagricraft-8e897.firebaseapp.com",
  projectId: "eagricraft-8e897",
  storageBucket: "eagricraft-8e897.appspot.com",
  messagingSenderId: "231335622264",
  appId: "1:231335622264:web:c6b52311c4e37681d71f8d",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
