import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Box,
  Typography,
} from "@mui/material";
import { jsPDF } from "jspdf";
import { useTranslation } from "react-i18next";
import { db } from "../firebaseConfig"; // Import Firestore instance
import { collection, addDoc } from "firebase/firestore"; // Firestore functions

const FPOLivestockForm = () => {
  const { t } = useTranslation(); // Translation hook
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    trigger,
    reset,
  } = useForm({ mode: "onChange" });

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const [isSummaryVisible, setIsSummaryVisible] = useState(false);

  const steps = [
    "sellerInformation",
    "livestockInformation",
    "pricingAndTransactionDetails",
    "summary",
  ];

  const nextStep = async () => {
    const isStepValid = await trigger(); // Trigger validation for current step
    if (isStepValid) {
      setFormData((prevData) => ({ ...prevData, ...getValues() })); // Store form data
      setStep((prevStep) => prevStep + 1); // Move to the next step
    }
  };

  const prevStep = () => setStep((prevStep) => prevStep - 1);

  const onSubmit = async () => {
    const data = getValues();
    setFormData(data);
    setStep(steps.length - 1); // Move to the summary step
    setIsSummaryVisible(true); // Show the summary view

    try {
      // Save form data to Firestore
      const docRef = await addDoc(collection(db, "FPOLivestockForms"), data);
      window.alert("Form has been successfully saved!"); // Show success message

      // Optionally, reset form after submission
      reset();
      setStep(0); // Reset step to initial state
    } catch (e) {
      window.alert("Error adding document: ", e);
    }
  };

  const generatePDF = (data) => {
    const doc = new jsPDF();
    doc.text(t("pdfTitle"), 10, 10); // Use translation
    let y = 20;
    Object.keys(data).forEach((key) => {
      doc.text(`${t(key)}: ${data[key]}`, 10, y); // Use translation
      y += 10;
    });
    doc.save("FPOLivestockFormSummary.pdf");
  };

  const handleGeneratePDF = () => {
    generatePDF(formData);
  };

  useEffect(() => {
    const fieldsToValidate =
      step === 0
        ? ["nameOfSeller", "address", "phoneNumber"]
        : step === 1
        ? ["typeOfLivestock", "age", "weight"]
        : step === 2
        ? ["pricePerUnit", "totalPrice"]
        : [];

    const checkValidity = async () => {
      const isValidStep = await trigger(fieldsToValidate);
      console.log(`Step ${step} isValid:`, isValidStep); // Debugging
    };

    checkValidity();
  }, [step, trigger]);

  const checkboxOptions = {
    deliveryMethod: ["Home Delivery", "Pickup", "Other"],
    paymentMode: ["Cash", "Bank Transfer", "Online"],
    purposeOfSale: ["Breeding", "Dairy", "Meat", "Other"],
    diet: ["Grass", "Grain", "Mixed"],
    pregnancyStatus: ["Not Pregnant", "Pregnant"],
    healthStatus: ["Healthy", "Under Treatment", "Other"],
    typeOfLivestock: ["Cattle", "Goat", "Sheep"],
    gender: ["Male", "Female"],
  };

  const renderCheckboxes = (name, options) => {
    const optionsArray = Array.isArray(options)
      ? options
      : Object.values(options);
    return (
      <FormControl component="fieldset" sx={{ marginBottom: "15px" }}>
        <FormLabel component="legend" sx={{ color: "black" }}>
          {t(name)}
        </FormLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <Box>
              {optionsArray.map((option) => (
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      {...field}
                      value={option}
                      checked={field.value.includes(option)}
                      onChange={(e) => {
                        const { value, checked } = e.target;
                        if (checked) {
                          field.onChange([...field.value, value]);
                        } else {
                          field.onChange(
                            field.value.filter((v) => v !== value)
                          );
                        }
                      }}
                    />
                  }
                  label={t(option)} // Use translation
                />
              ))}
            </Box>
          )}
        />
      </FormControl>
    );
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        width: "100%",
        maxWidth: "600px",
        margin: "auto",
        padding: "20px",
        backgroundColor: "white",
      }}
    >
      {step === 0 && (
        <>
          <Typography variant="h6" sx={{ color: "black" }}>
            {t("sellerInformation")}
          </Typography>
          <TextField
            label={t("nameOfSeller")}
            {...register("nameOfSeller", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.nameOfSeller}
            helperText={errors.nameOfSeller ? t("nameRequired") : ""}
            sx={{ color: "black" }}
          />
          <TextField
            label={t("fpoMembershipId")}
            {...register("fpoMembershipId")}
            fullWidth
            margin="normal"
            sx={{ color: "black" }}
          />
          <TextField
            label={t("farmName")}
            {...register("farmName")}
            fullWidth
            margin="normal"
            sx={{ color: "black" }}
          />
          <TextField
            label={t("address")}
            {...register("address", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.address}
            helperText={errors.address ? t("addressRequired") : ""}
            sx={{ color: "black" }}
          />
          <TextField
            label={t("phoneNumber")}
            {...register("phoneNumber", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber ? t("phoneNumberRequired") : ""}
            sx={{ color: "black" }}
          />
          <TextField
            label={t("emailAddress")}
            {...register("emailAddress")}
            fullWidth
            margin="normal"
            sx={{ color: "black" }}
          />
          <TextField
            label={t("aadhaarNumber")}
            {...register("aadhaarNumber")}
            fullWidth
            margin="normal"
            sx={{ color: "black" }}
          />
        </>
      )}

      {step === 1 && (
        <>
          <Typography variant="h6" sx={{ color: "black" }}>
            {t("livestockInformation")}
          </Typography>
          {renderCheckboxes("typeOfLivestock", checkboxOptions.typeOfLivestock)}
          <TextField
            label={t("breedName")}
            {...register("breedName")}
            fullWidth
            margin="normal"
            sx={{ color: "black" }}
          />
          <TextField
            label={t("age")}
            type="number"
            {...register("age", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.age}
            helperText={errors.age ? t("ageRequired") : ""}
            sx={{ color: "black" }}
          />
          <TextField
            label={t("weight")}
            type="number"
            {...register("weight", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.weight}
            helperText={errors.weight ? t("weightRequired") : ""}
            sx={{ color: "black" }}
          />
        </>
      )}

      {step === 2 && (
        <>
          <Typography variant="h6" sx={{ color: "black" }}>
            {t("pricingAndTransactionDetails")}
          </Typography>
          <TextField
            label={t("pricePerUnit")}
            type="number"
            {...register("pricePerUnit", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.pricePerUnit}
            helperText={errors.pricePerUnit ? t("pricePerUnitRequired") : ""}
            sx={{ color: "black" }}
          />
          <TextField
            label={t("totalPrice")}
            type="number"
            {...register("totalPrice", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.totalPrice}
            helperText={errors.totalPrice ? t("totalPriceRequired") : ""}
            sx={{ color: "black" }}
          />
          {renderCheckboxes("paymentMode", checkboxOptions.paymentMode)}
          <TextField
            label={t("bankDetails")}
            {...register("bankDetails")}
            fullWidth
            margin="normal"
            sx={{ color: "black" }}
          />
        </>
      )}

      {step === 3 && (
        <>
          <Typography variant="h6" sx={{ color: "black" }}>
            {t("summary")}
          </Typography>
          <Box sx={{ marginBottom: "20px" }}>
            {Object.keys(formData).map((key) => {
              // Exclude specific fields from the summary
              if (
                key === "agreeToTerms" ||
                key === "signature" ||
                key === "date"
              ) {
                return null;
              }

              // Format arrays for display
              let value = formData[key];
              if (Array.isArray(value)) {
                value = value.join(", ");
              }

              return (
                <Typography key={key} sx={{ marginBottom: "10px" }}>
                  <strong>{t(key)}:</strong> {value}
                </Typography>
              );
            })}
          </Box>
          <Typography variant="body1" sx={{ marginBottom: "15px" }}>
            <strong>{t("agreeToTerms")}</strong>
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "15px" }}>
            <strong>{t("signature")}:</strong> {formData.signature}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "15px" }}>
            <strong>{t("date")}:</strong> {formData.date}
          </Typography>

          <Button
            variant="contained"
            onClick={handleGeneratePDF}
            sx={{
              backgroundColor: "#4CA80D",
              color: "white",
              marginTop: "10px",
            }}
          >
            {t("generatePDF")}
          </Button>
        </>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        {step > 0 && (
          <Button
            variant="contained"
            color="primary"
            onClick={prevStep}
            sx={{ backgroundColor: "#4CA80D" }}
          >
            {t("previous")}
          </Button>
        )}
        {step < steps.length - 1 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={nextStep}
            sx={{ backgroundColor: "#4CA80D" }}
          >
            {t("next")}
          </Button>
        ) : (
          <>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ backgroundColor: "#4CA80D" }}
            >
              {t("submit")}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default FPOLivestockForm;
