// src/FPOForm.js
import React from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./LanguageSwitcher"; // Ensure the path is correct
import FPOLivestockForm from "./components/FPOLivestockForm"; // Adjust path as needed
import { Container, Box, Typography, Paper } from "@mui/material";
import "./FPOForm.css";

const FPOForm = () => {
  const { t } = useTranslation();

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginTop: 4,
        }}
      >
        <Paper elevation={3} sx={{ padding: 3, width: "100%", maxWidth: 800 }}>
          <Typography variant="h4" component="h1" align="center" gutterBottom>
            {t("FPO Forms")}
          </Typography>
          <LanguageSwitcher />
          <FPOLivestockForm />
        </Paper>
      </Box>
    </Container>
  );
};

export default FPOForm;
