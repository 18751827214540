// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import BuyerForm from './BuyerForm';
import SellerForm from './SellerForm';
import FPOForm from './FPOForm';
import './App.css'; // Optional: For global styling

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/buyer-form" element={<BuyerForm />} />
        <Route path="/seller-form" element={<SellerForm />} />
        <Route path="/fpo-form" element={<FPOForm />} />
      </Routes>
    </Router>
  );
};

export default App;

