// src/HomePage.js
import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css'; // Optional: For styling

const HomePage = () => {
  return (
    <div className="homepage">
      <h1>Livestock Trading Forms</h1>
      <div className="form-links">
        <Link to="/buyer-form" className="form-link">Buyer Livestock Trading Form</Link>
        <Link to="/seller-form" className="form-link">Seller Livestock Trading Form</Link>
        <Link to="/fpo-form" className="form-link">FPO Livestock Trading Form</Link>
      </div>
    </div>
  );
};

export default HomePage;

