import React from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import styled from 'styled-components';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const handleLanguageChange = (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage)
      .then(() => {
        console.log(`Language changed to: ${selectedLanguage}`);
      })
      .catch((error) => {
        console.error('Error changing language:', error);
      });
  };

  return (
    <StyledFormControl component="fieldset">
      <FormLabel component="legend">Select Language</FormLabel>
      <RadioGroup
        aria-label="language"
        name="language"
        value={i18n.language}
        onChange={handleLanguageChange}
        row // Display items in a row
      >
        <FormControlLabel value="en" control={<Radio />} label="English" />
        <FormControlLabel value="kn" control={<Radio />} label="ಕನ್ನಡ" />
      </RadioGroup>
    </StyledFormControl>
  );
};

const StyledFormControl = styled(FormControl)`
  display: flex;
  flex-direction: column; /* Ensure FormControl is a column container */
  align-items: center; /* Center items horizontally */
  justify-content: center; /* Center items vertically */
  text-align: center; /* Center text alignment */
  margin: 0 auto; /* Center FormControl itself horizontally */
`;

export default LanguageSwitcher;

