import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  FormControl,
  FormLabel,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { jsPDF } from "jspdf";
import { db } from "../firebaseConfig"; // Import Firestore instance
import { collection, addDoc } from "firebase/firestore"; // Firestore functions

const BuyerLivestockForm = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    getValues,
    trigger,
    watch,
  } = useForm({ mode: "onChange" });
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});

  const steps = [
    "buyerInformation",
    "purchaseDetails",
    "buyersDeclaration",
    "platformDetails",
    "summary",
  ];

  // Function to validate and go to the next step
  const nextStep = async () => {
    const isStepValid = await trigger(); // Trigger validation for current step
    if (isStepValid) {
      setFormData((prevData) => ({ ...prevData, ...getValues() }));
      setStep((prevStep) => prevStep + 1);
    }
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const onSubmit = async () => {
    // Only submit when on the last step

    const data = getValues();
    setFormData(data);
    setStep(steps.length - 1); // Move to the summary step
    data.agreeToTerms = t("agreeToTerms");

    try {
      await saveToFirestore(data); // Save data to Firestore
      window.alert("Form has been successfully saved!"); // Show success message
      setStep(0); // Reset to the first step if needed
    } catch (error) {
      console.error("Error saving form data:", error);
      window.alert(
        "An error occurred while saving the form. Please try again."
      ); // Show error message
    }
  };

  // Function to save form data to Firestore
  const saveToFirestore = async (data) => {
    try {
      await addDoc(collection(db, "buyerLivestockForms"), data);
      console.log("Document successfully written!");
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const generatePDF = (data) => {
    const doc = new jsPDF();
    doc.text("Buyer Livestock Form Summary", 10, 10);
    let y = 20;

    Object.keys(data).forEach((key) => {
      let value = data[key];
      if (Array.isArray(value)) {
        value = value.join(", ");
      } else if (typeof value !== "string") {
        value = String(value); // Convert non-string values to string
      }

      const label = t(key);
      if (label) {
        // Ensure translation is not undefined
        if (key !== "agreeToTerms" && key !== "signature" && key !== "date") {
          doc.text(`${label.toUpperCase()}: ${value}`, 10, y);
          y += 10;
        }
      }
    });

    // Add Buyer's Declaration text and signature details
    y += 10;
    doc.text(`${t("buyersDeclaration").toUpperCase()}:`, 10, y); // Uppercase heading
    y += 10;
    doc.text(data.agreeToTerms || "", 10, y); // Ensure agreeToTerms is not undefined
    y += 10;
    doc.text(`${t("signature")}: ${data.signature || ""}`, 10, y); // Ensure signature is not undefined
    y += 10;
    doc.text(`${t("date")}: ${data.date || ""}`, 10, y); // Ensure date is not undefined

    // Save the PDF
    doc.save("BuyerLivestockFormSummary.pdf");
  };

  // Effect to check field validity
  useEffect(() => {
    const checkValidity = async () => {
      let fieldsToValidate = [];
      switch (step) {
        case 0:
          fieldsToValidate = ["name", "contactNumber", "address"];
          break;
        case 1:
          fieldsToValidate = [
            "typeOfLivestock",
            "breedPreference",
            "agePreference",
            "genderPreference",
            "deliveryMethod",
          ];
          break;
        case 2:
          fieldsToValidate = ["signature", "date"];
          break;
        case 3:
          fieldsToValidate = ["transactionID", "dateOfTransaction"];
          break;
        default:
          fieldsToValidate = [];
      }

      // Manually validate fields for the current step
      await trigger(fieldsToValidate);
    };

    checkValidity();
  }, [step, trigger]);

  // Watch relevant fields to trigger validation
  const fieldsToWatch =
    step === 0
      ? ["name", "contactNumber", "address"]
      : step === 1
      ? [
          "typeOfLivestock",
          "breedPreference",
          "agePreference",
          "genderPreference",
          "deliveryMethod",
        ]
      : step === 2
      ? ["signature", "date"]
      : step === 3
      ? ["transactionID", "dateOfTransaction"]
      : [];

  useEffect(() => {
    const subscription = watch(async () => {
      await trigger(fieldsToWatch);
    });

    return () => subscription.unsubscribe(); // Cleanup subscription
  }, [watch, step, trigger]);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)} //
      sx={{
        width: "100%",
        maxWidth: "600px",
        margin: "auto",
        padding: "20px",
        backgroundColor: "white",
      }}
    >
      {step === 0 && (
        <>
          <Typography variant="h6" sx={{ color: "black" }}>
            {t("buyersInformation")}
          </Typography>
          <TextField
            label={t("name")}
            {...register("name", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name ? t("nameRequired") : ""}
          />
          <TextField
            label={t("contactNumber")}
            {...register("contactNumber", {
              required: true,
              pattern: {
                value: /^[0-9]+$/,
                message: t("contactNumberOnlyNumbers"),
              },
            })}
            fullWidth
            margin="normal"
            error={!!errors.contactNumber}
            helperText={
              errors.contactNumber ? errors.contactNumber.message : ""
            }
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          />
          <TextField
            label={t("email")}
            {...register("email")}
            fullWidth
            margin="normal"
          />
          <TextField
            label={t("address")}
            {...register("address", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.address}
            helperText={errors.address ? t("addressRequired") : ""}
          />
        </>
      )}

      {step === 1 && (
        <>
          <Typography variant="h6" sx={{ color: "black" }}>
            {t("purchaseDetails")}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl component="fieldset" sx={{ marginBottom: "15px" }}>
                <FormLabel component="legend" sx={{ color: "black" }}>
                  {t("typeOfLivestock")}
                </FormLabel>
                <FormGroup>
                  <Controller
                    name="typeOfLivestock"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value.includes("cattle")}
                              onChange={() => {
                                if (field.value.includes("cattle")) {
                                  field.onChange(
                                    field.value.filter(
                                      (val) => val !== "cattle"
                                    )
                                  );
                                } else {
                                  field.onChange([...field.value, "cattle"]);
                                }
                              }}
                            />
                          }
                          label={t("cattle")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value.includes("goat")}
                              onChange={() => {
                                if (field.value.includes("goat")) {
                                  field.onChange(
                                    field.value.filter((val) => val !== "goat")
                                  );
                                } else {
                                  field.onChange([...field.value, "goat"]);
                                }
                              }}
                            />
                          }
                          label={t("goat")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value.includes("sheep")}
                              onChange={() => {
                                if (field.value.includes("sheep")) {
                                  field.onChange(
                                    field.value.filter((val) => val !== "sheep")
                                  );
                                } else {
                                  field.onChange([...field.value, "sheep"]);
                                }
                              }}
                            />
                          }
                          label={t("sheep")}
                        />
                      </>
                    )}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("breedPreference")}
                {...register("breedPreference")}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("agePreference")}
                {...register("agePreference")}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" sx={{ marginBottom: "15px" }}>
                <FormLabel component="legend" sx={{ color: "black" }}>
                  {t("genderPreference")}
                </FormLabel>
                <FormGroup>
                  <Controller
                    name="genderPreference"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value.includes("male")}
                              onChange={() => {
                                if (field.value.includes("male")) {
                                  field.onChange(
                                    field.value.filter((val) => val !== "male")
                                  );
                                } else {
                                  field.onChange([...field.value, "male"]);
                                }
                              }}
                            />
                          }
                          label={t("male")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value.includes("female")}
                              onChange={() => {
                                if (field.value.includes("female")) {
                                  field.onChange(
                                    field.value.filter(
                                      (val) => val !== "female"
                                    )
                                  );
                                } else {
                                  field.onChange([...field.value, "female"]);
                                }
                              }}
                            />
                          }
                          label={t("female")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value.includes("noPreference")}
                              onChange={() => {
                                if (field.value.includes("noPreference")) {
                                  field.onChange(
                                    field.value.filter(
                                      (val) => val !== "noPreference"
                                    )
                                  );
                                } else {
                                  field.onChange([
                                    ...field.value,
                                    "noPreference",
                                  ]);
                                }
                              }}
                            />
                          }
                          label={t("noPreference")}
                        />
                      </>
                    )}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" sx={{ marginBottom: "15px" }}>
                <FormLabel component="legend" sx={{ color: "black" }}>
                  {t("deliveryMethod")}
                </FormLabel>
                <FormGroup>
                  <Controller
                    name="deliveryMethod"
                    control={control}
                    defaultValue={[]}
                    render={({ field }) => (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value.includes("sellersLocation")}
                              onChange={() => {
                                if (field.value.includes("sellersLocation")) {
                                  field.onChange(
                                    field.value.filter(
                                      (val) => val !== "sellersLocation"
                                    )
                                  );
                                } else {
                                  field.onChange([
                                    ...field.value,
                                    "sellersLocation",
                                  ]);
                                }
                              }}
                            />
                          }
                          label={t("sellersLocation")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value.includes("buyersLocation")}
                              onChange={() => {
                                if (field.value.includes("buyersLocation")) {
                                  field.onChange(
                                    field.value.filter(
                                      (val) => val !== "buyersLocation"
                                    )
                                  );
                                } else {
                                  field.onChange([
                                    ...field.value,
                                    "buyersLocation",
                                  ]);
                                }
                              }}
                            />
                          }
                          label={t("buyersLocation")}
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              checked={field.value.includes(
                                "thirdPartyLogistics"
                              )}
                              onChange={() => {
                                if (
                                  field.value.includes("thirdPartyLogistics")
                                ) {
                                  field.onChange(
                                    field.value.filter(
                                      (val) => val !== "thirdPartyLogistics"
                                    )
                                  );
                                } else {
                                  field.onChange([
                                    ...field.value,
                                    "thirdPartyLogistics",
                                  ]);
                                }
                              }}
                            />
                          }
                          label={t("thirdPartyLogistics")}
                        />
                      </>
                    )}
                  />
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label={t("additionalComments")}
                {...register("additionalComments")}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        </>
      )}

      {step === 2 && (
        <>
          <Typography variant="h6" sx={{ color: "black" }}>
            {t("buyersDeclaration")}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "15px" }}>
            {t("agreeToTerms")}
          </Typography>
          <TextField
            label={t("signature")}
            {...register("signature", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.signature}
            helperText={errors.signature ? t("signatureRequired") : ""}
          />
          <TextField
            type="date"
            label={t("date")}
            {...register("date", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.date}
            helperText={errors.date ? t("dateRequired") : ""}
          />
        </>
      )}

      {step === 3 && (
        <>
          <Typography variant="h6" sx={{ color: "black" }}>
            {t("platformDetails")}
          </Typography>
          <TextField
            label={t("transactionID")}
            {...register("transactionID")}
            fullWidth
            margin="normal"
          />
          <TextField
            type="date"
            label={t("dateOfTransaction")}
            {...register("dateOfTransaction")}
            fullWidth
            margin="normal"
          />
        </>
      )}

      {step === 4 && (
        <>
          <Typography variant="h6" sx={{ color: "black" }}>
            {t("summary")}
          </Typography>
          <Box sx={{ marginBottom: "20px" }}>
            {Object.keys(formData).map((key) => {
              // Exclude 'agreeToTerms' from the main summary section
              if (
                key === "agreeToTerms" ||
                key === "signature" ||
                key === "date"
              ) {
                return null;
              }

              // Format arrays for display
              let value = formData[key];
              if (Array.isArray(value)) {
                value = value.join(", ");
              }

              return (
                <Typography key={key} sx={{ marginBottom: "10px" }}>
                  <strong>{t(key)}:</strong> {value}
                </Typography>
              );
            })}
          </Box>
          <Typography variant="body1" sx={{ marginBottom: "15px" }}>
            <strong>{t("agreeToTerms")}</strong>
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "15px" }}>
            <strong>{t("signature")}:</strong> {formData.signature}
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: "15px" }}>
            <strong>{t("date")}:</strong> {formData.date}
          </Typography>

          <Button
            variant="contained"
            onClick={() => generatePDF(formData)}
            sx={{ backgroundColor: "#4CA80D", color: "white" }}
          >
            {t("downloadPDF")}
          </Button>
        </>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        {step > 0 && (
          <Button
            variant="contained"
            onClick={prevStep}
            sx={{ backgroundColor: "#4CA80D", color: "white" }}
          >
            {t("previous")}
          </Button>
        )}
        {step < steps.length - 1 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={nextStep}
            sx={{ backgroundColor: "#4CA80D" }}
          >
            {t("next")}
          </Button>
        ) : (
          <>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ backgroundColor: "#4CA80D" }}
            >
              {t("submit")}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default BuyerLivestockForm;
