import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  TextField,
  Button,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
  RadioGroup,
  Radio,
  Box,
  Typography,
  Grid,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { jsPDF } from "jspdf"; // Import jsPDF for PDF generation
import { db } from "../firebaseConfig"; // Import the Firestore instance
import { collection, addDoc } from "firebase/firestore"; // Import Firestore functions

const SellerLivestockForm = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isValid },
    getValues,
    trigger,
  } = useForm({ mode: "onChange" });

  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({});
  const steps = [
    "sellerInformation",
    "livestockInformation",
    "saleDetails",
    "sellersDeclaration",
    "summary", // New step for summary and PDF download
  ];

  const nextStep = async () => {
    const isStepValid = await trigger(); // Trigger validation for current step
    if (isStepValid) {
      setFormData((prevData) => ({ ...prevData, ...getValues() })); // Store form data
      setStep((prevStep) => prevStep + 1); // Move to the next step
    }
  };

  const prevStep = () => setStep((prevStep) => prevStep - 1);

  const onSubmit = async () => {
    const data = getValues();
    setFormData(data);
    try {
      await saveToFirestore(data); // Save data to Firestore
      window.alert("Form has been successfully saved!"); // Show success message
      setStep(0); // Reset to the first step if needed
    } catch (error) {
      console.error("Error saving form data:", error);
      window.alert(
        "An error occurred while saving the form. Please try again."
      ); // Show error message
    }
  };

  const saveToFirestore = async (data) => {
    try {
      const docRef = await addDoc(collection(db, "sellerLivestockForms"), data);
      console.log("Document written with ID: ", docRef.id);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const generatePDF = (data) => {
    const doc = new jsPDF();
    doc.text("Seller Livestock Form Summary", 10, 10);
    let y = 20;

    Object.keys(data).forEach((key) => {
      let value = data[key];
      if (Array.isArray(value)) {
        value = value.join(", ");
      } else if (typeof value !== "string") {
        value = JSON.stringify(value);
      }
      if (
        key !== "signature" &&
        key !== "date" &&
        key !== "declareInformation"
      ) {
        doc.text(`${t(key)}: ${value}`, 10, y);
        y += 10;
      }
    });

    doc.text(
      `${t("sellersDeclaration")}: ${data.sellersDeclaration || ""}`,
      10,
      y
    );
    y += 10;
    doc.text(
      `${t("declareInformation")}: ${data.declareInformation || ""}`,
      10,
      y
    );
    y += 10;

    // Add signature
    doc.text(`${t("signature")}: ${data.signature || ""}`, 10, y);
    y += 10;

    // Add date
    doc.text(`${t("date")}: ${data.date || ""}`, 10, y);

    doc.save("SellerLivestockFormSummary.pdf");
  };

  useEffect(() => {
    const fieldsToValidate =
      step === 0
        ? ["name", "contactNumber", "address"]
        : step === 1
        ? ["typeOfLivestock", "age", "weight"]
        : step === 2
        ? ["price"]
        : step === 3
        ? ["signature", "date"]
        : [];

    const checkValidity = async () => {
      const isValidStep = await trigger(fieldsToValidate);
      console.log(`Step ${step} isValid:`, isValidStep); // Debugging
    };

    checkValidity();
  }, [step, trigger]);

  const SummarySection = () => (
    <Box>
      <Typography variant="h6" sx={{ color: "black" }}>
        {t("summary")}
      </Typography>
      <Box sx={{ marginTop: "20px" }}>
        {Object.keys(formData).map(
          (key) =>
            key !== "signature" &&
            key !== "date" &&
            key !== "declareInformation" && (
              <Typography key={key} sx={{ color: "black" }}>
                {t(key)}:{" "}
                {Array.isArray(formData[key])
                  ? formData[key].join(", ")
                  : formData[key]}
              </Typography>
            )
        )}
        <Typography sx={{ color: "black" }}>
          {t("sellersDeclaration")}: {formData.sellersDeclaration || ""}
        </Typography>
        <Typography sx={{ color: "black" }}>
          {t("declareInformation")}: {formData.declareInformation || ""}
        </Typography>
        <Typography sx={{ color: "black" }}>
          {t("signature")}: {formData.signature || ""}
        </Typography>
        <Typography sx={{ color: "black" }}>
          {t("date")}: {formData.date || ""}
        </Typography>
      </Box>
      <Button
        variant="contained"
        onClick={() => generatePDF(formData)}
        sx={{ color: "white", backgroundColor: "#4CA80D", marginTop: "20px" }}
      >
        {t("downloadPDF")}
      </Button>
    </Box>
  );

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        width: "100%",
        maxWidth: "600px",
        margin: "auto",
        padding: "20px",
        backgroundColor: "white",
      }}
    >
      {step === 0 && (
        <>
          <Typography variant="h6" sx={{ color: "black" }}>
            {t("sellerInformation")}
          </Typography>
          <TextField
            label={t("name")}
            {...register("name", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.name}
            helperText={errors.name ? t("nameRequired") : ""}
            sx={{ color: "black" }}
          />
          <TextField
            label={t("contactNumber")}
            {...register("contactNumber", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.contactNumber}
            helperText={errors.contactNumber ? t("contactNumberRequired") : ""}
            sx={{ color: "black" }}
          />
          <TextField
            label={t("email")}
            {...register("email")}
            fullWidth
            margin="normal"
            sx={{ color: "black" }}
          />
          <TextField
            label={t("address")}
            {...register("address", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.address}
            helperText={errors.address ? t("addressRequired") : ""}
            sx={{ color: "black" }}
          />
        </>
      )}

      {step === 1 && (
        <>
          <Typography variant="h6" sx={{ color: "black" }}>
            {t("livestockInformation")}
          </Typography>
          <FormControl component="fieldset" sx={{ marginBottom: "15px" }}>
            <FormLabel component="legend" sx={{ color: "black" }}>
              {t("typeOfLivestock")}
            </FormLabel>
            <Controller
              name="typeOfLivestock"
              control={control}
              defaultValue={[]}
              render={({ field }) => (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value.includes("cattle")}
                        onChange={() => {
                          const newValue = field.value.includes("cattle")
                            ? field.value.filter((val) => val !== "cattle")
                            : [...field.value, "cattle"];
                          field.onChange(newValue);
                        }}
                      />
                    }
                    label={t("cattle")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value.includes("goat")}
                        onChange={() => {
                          const newValue = field.value.includes("goat")
                            ? field.value.filter((val) => val !== "goat")
                            : [...field.value, "goat"];
                          field.onChange(newValue);
                        }}
                      />
                    }
                    label={t("goat")}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={field.value.includes("sheep")}
                        onChange={() => {
                          const newValue = field.value.includes("sheep")
                            ? field.value.filter((val) => val !== "sheep")
                            : [...field.value, "sheep"];
                          field.onChange(newValue);
                        }}
                      />
                    }
                    label={t("sheep")}
                  />
                </FormGroup>
              )}
            />
          </FormControl>

          <TextField
            label={t("breed")}
            {...register("breed")}
            fullWidth
            margin="normal"
            sx={{ color: "black" }}
          />
          <TextField
            label={t("age")}
            type="number"
            {...register("age", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.age}
            helperText={errors.age ? t("ageRequired") : ""}
            sx={{ color: "black" }}
          />
          <FormControl component="fieldset" sx={{ marginBottom: "15px" }}>
            <FormLabel component="legend" sx={{ color: "black" }}>
              {t("gender")}
            </FormLabel>
            <Controller
              name="gender"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label={t("male")}
                  />
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label={t("female")}
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          <TextField
            label={t("weight")}
            type="number"
            {...register("weight", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.weight}
            helperText={errors.weight ? t("weightRequired") : ""}
            sx={{ color: "black" }}
          />
          <FormControl component="fieldset" sx={{ marginBottom: "15px" }}>
            <FormLabel component="legend" sx={{ color: "black" }}>
              {t("healthStatus")}
            </FormLabel>
            <Controller
              name="healthStatus"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value="healthy"
                    control={<Radio />}
                    label={t("healthy")}
                  />
                  <FormControlLabel
                    value="underTreatment"
                    control={<Radio />}
                    label={t("underTreatment")}
                  />
                </RadioGroup>
              )}
            />
          </FormControl>
          <Grid item xs={12}>
            <FormControl component="fieldset" sx={{ marginBottom: "15px" }}>
              <FormLabel component="legend" sx={{ color: "black" }}>
                {t("vaccinated")}
              </FormLabel>
              <Controller
                name="vaccinated"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label={t("yes")}
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label={t("no")}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset" sx={{ marginBottom: "15px" }}>
              <FormLabel component="legend" sx={{ color: "black" }}>
                {t("pregnancyStatus")}
              </FormLabel>
              <Controller
                name="pregnancyStatus"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <FormControlLabel
                      value="pregnant"
                      control={<Radio />}
                      label={t("pregnant")}
                    />
                    <FormControlLabel
                      value="notPregnant"
                      control={<Radio />}
                      label={t("notPregnant")}
                    />
                    <FormControlLabel
                      value="recentlyGivenBirth"
                      control={<Radio />}
                      label={t("recentlyGivenBirth")}
                    />
                  </RadioGroup>
                )}
              />
            </FormControl>
          </Grid>
        </>
      )}

      {step === 2 && (
        <>
          <Typography variant="h6" sx={{ color: "black" }}>
            {t("saleDetails")}
          </Typography>
          <TextField
            label={t("price")}
            type="number"
            {...register("price", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.price}
            helperText={errors.price ? t("priceRequired") : ""}
            sx={{ color: "black" }}
          />
          <TextField
            label={t("additionalInformation")}
            {...register("additionalInformation")}
            fullWidth
            margin="normal"
            multiline
            rows={4}
            sx={{ color: "black" }}
          />
        </>
      )}

      {step === 3 && (
        <>
          <Typography variant="h6" sx={{ color: "black" }}>
            {t("sellersDeclaration")}
          </Typography>
          <Typography variant="h6" sx={{ color: "black" }}>
            {t("declareInformation")}
          </Typography>
          <TextField
            label={t("signature")}
            {...register("signature", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.signature}
            helperText={errors.signature ? t("signatureRequired") : ""}
            sx={{ color: "black" }}
          />
          <TextField
            label={t("date")}
            type="date"
            {...register("date", { required: true })}
            fullWidth
            margin="normal"
            error={!!errors.date}
            helperText={errors.date ? t("dateRequired") : ""}
            InputLabelProps={{ shrink: true }}
            sx={{ color: "black" }}
          />
        </>
      )}

      {step === 4 && <SummarySection />}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
        }}
      >
        {step > 0 && (
          <Button
            type="button"
            variant="contained"
            onClick={prevStep}
            sx={{ color: "white", backgroundColor: "#4CA80D" }}
          >
            {t("previous")}
          </Button>
        )}
        {step < steps.length - 1 ? (
          <Button
            type="button"
            variant="contained"
            onClick={nextStep}
            sx={{ color: "white", backgroundColor: "#4CA80D" }}
          >
            {t("next")}
          </Button>
        ) : (
          <>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ backgroundColor: "#4CA80D" }}
            >
              {t("submit")}
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default SellerLivestockForm;
