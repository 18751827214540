import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import JSON files for each language
import enBuyerForm from './locales/en/buyerForm.json';
import enSellerForm from './locales/en/sellerForm.json';
import enFpoForm from './locales/en/fpoForm.json';

import knBuyerForm from './locales/kn/buyerForm.json';
import knSellerForm from './locales/kn/sellerForm.json';
import knFpoForm from './locales/kn/fpoForm.json';

// Combine translation files
const resources = {
  en: {
    translation: {
      ...enBuyerForm,
      ...enSellerForm,
      ...enFpoForm
    }
  },
  kn: {
    translation: {
      ...knBuyerForm,
      ...knSellerForm,
      ...knFpoForm
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // Default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
